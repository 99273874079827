@tailwind base;
@tailwind components;
@tailwind utilities;

/* General Styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ----- Navigation Bar ----- */
.nav-link {
  color: white;
  display: inline-block;
  transition: color 0.3s, transform 0.3s;
  text-align: center;
}

.nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: white;
  transition: width 0.3s;
}

.nav-link:hover {
  color: white !important;
  transform: scale(1.10);
}

.nav-link:hover::after {
  width: 100%;
}

.nav-link-inv {
  color: #2c3e50;
  display: inline-block;
  transition: color 0.3s, transform 0.3s;
  text-align: center;
}

.nav-link-inv::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: #2c3e50;
  transition: width 0.3s;
}

.nav-link-inv:hover {
  color: #2c3e50 !important;
  transform: scale(1.10);
}

.nav-link-inv:hover::after {
  width: 100%;
}

/* Mobile Menu */
.mobile-menu {
  height: 0;
  opacity: 0;
  overflow: hidden;
  border-radius: 30px;
  transition: height 0.5s ease, opacity 0.5s ease;
}

.mobile-menu.open {
  height: 100%;
  opacity: 1;
}

/* Sidebar */
.sidebar-nav {
  @apply hidden md:flex fixed flex-col top-[35%] left-0;
}


.sidebar-floating-box {
  @apply w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[15px] duration-300 bg-gradient-to-tr rounded-2xl;
}

.sidebar-text {
  @apply flex justify-between items-center w-full text-white;
}

/* ----- END Navigation Bar ----- */

/* ----- Home Page ----- */

.home {
  position: relative; /* relative */
  display: flex; /* flex */
  flex-direction: column; /* flex-col */
  align-items: center; /* items-center */
  justify-content: center; /* justify-center */
  color: white; /* text-white */
  min-height: 100vh; /* min-h-screen */
  padding-left: 2rem; /* px-8 */
  padding-right: 2rem; /* px-8 */
}

@media (min-width: 768px) {
  .home {
    flex-direction: row; /* md:flex-row */
    padding-left: 4rem; /* md:px-16 */
    padding-right: 4rem; /* md:px-16 */
  }
}

@media (min-width: 1280px) {
  .home {
    padding-left: 6rem; /* xl:px-24 */
    padding-right: 6rem; /* xl:px-24 */
  }
}

.home {
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh; /* Ensure it covers full viewport height */
}

.home-color {
  background: linear-gradient(90deg, #000000 0%, #2c3e50 50%, #2c3e50 100%);
}

.landing-page {
  @apply flex flex-col md:flex-row items-center justify-between w-full max-w-5xl py-0 md:space-x-8 lg:space-x-10 md:px-8 lg:px-2;
}

.home-container {
  @apply flex-shrink-0 mb-6 md:mb-0 transition-all duration-1000 ease-in-out transform;
}

.home-text-container {
  @apply flex flex-col items-center md:items-stretch md:w-2/3 space-y-4 text-center md:text-left px-4 md:px-0 transition-all duration-1000 ease-in-out transform;
}

.hello {
  font-size: 1rem;
  font-weight: bold;
  @apply text-2xl md:text-3xl;
}

.hello-adi {
  background: linear-gradient(to right, #4facfe 0%, #87cefa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 2rem;
  font-weight: bold;
  @apply text-3xl md:text-4xl;
}

.landing-main-text {
  @apply flex flex-col sm:flex-row sm:space-x-4 md:flex-col;
}

.home-text-body {
  @apply mt-4 text-lg md:text-xl;
}

.swe {
  background: linear-gradient(to right, #98D8C1 0%, #CFFAE8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.come-see {
  background: linear-gradient(to right, #4facfe 0%, #87cefa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

/* Backgrounds */
.home-background {
  background: linear-gradient(to bottom, #000026 0%, #2c3e50 50%, #f8f8ff 100%);
  min-height: 100vh;
  color: white;
  padding: 100px;
}

/* Buttons */
.contact-btn {
  background: linear-gradient(135deg, #ffffff 0%, #d1e7f4 100%);
  color: #003366;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease;
  @apply text-xl;
  
}

.contact-btn:hover {
  transform: scale(1.1);
  color: #4facfe;
  background: linear-gradient(to right, #ffffff, #d1e7f4);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.contact-btn-inv {
  background: linear-gradient(to right, #2c3e50, #415f7c);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  @apply text-xl;
}

.contact-btn-inv:hover {
  transform: scale(1.1);
  background: linear-gradient(to right, #2c3e50, #415f7c);
  color: white;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.5);
  ;
}

/* ----- END Home Page ----- */

/* ----- Contact Page ----- */

/* Contact Form */
.contact {
  @apply flex flex-col md:flex-row items-center justify-center w-full min-h-screen bg-gray-100 md:pt-20 md:pr-20 lg:pl-40 md:pl-20 pt-14 px-5;
}

.contact-form-wrapper {
  background-color: #ffffff;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  border-radius: 20px;
  text-align: center;
  width: 90%;
  max-width: 500px;
  margin: 30px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  background: linear-gradient(to bottom right, #87CEEB 0%,  #4682B4 100%);
  @apply transition-all duration-1000 ease-in-out transform;
}

.reach-out-memoji {
  @apply w-56 md:w-64 lg:w-72 h-auto rounded-lg transition-all duration-300 ease-in-out hover:scale-105;
}

.reach-out {
  background: linear-gradient(90deg, #002244 0%, #3a96d8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @apply text-3xl md:text-4xl font-bold text-center p-1.5;
}

.reach-out-2 {
  background: linear-gradient(to right, #4facfe 0%, #87cefa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @apply text-3xl md:text-4xl font-bold text-center p-1.5;
}

.reach-out-3 {
  background: linear-gradient(to right, #4facfe 0%, #002244 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @apply reach-out text-2xl md:text-3xl italic font-thin text-center pt-10;
}

.contact-form-wrapper h2 {
  color: #304FFE;
  margin-bottom: 0.5rem;
}

.contact-form-wrapper p {
  color: #ffffff;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.contact-form {
  width: 100%;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  color: #6b6b6b;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
}

.contact-form button {
  background: linear-gradient(135deg, #ffffff 0%, #d1e7f4 100%);
  color: #003366;
  border: none;
  border-radius: 20px;
  padding: 0.8rem 1.6rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-form button:hover {
  transform: scale(1.1);
  color: #4facfe;
  background-image: linear-gradient(to right, #ffffff, #d1e7f4);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

/* Contact Info */
.contact-info-wrapper {
  background-color: transparent;
  text-align: left;
  
  max-width: auto;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  border-radius: 20px;
  min-height: 605px;
  background: linear-gradient(to bottom right, #ffffff 0%,  #c2e8ff 100%);
  @apply p-4 m-4 transition-all duration-1000 ease-in-out transform;
}

/* ----- END Contact Page ----- */

/* ----- About Me ----- */

.image-container img {
  animation: slideInFromLeft 1s ease-in-out forwards;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  flex-shrink: 0; /* flex-shrink-0 */
  margin-bottom: 1.5rem; /* mb-6 */
  width: 75%; /* w-3/4 */
  height: auto; /* h-auto */
  object-fit: cover; /* object-cover */
  opacity: 0; /* Ensure initial hidden state */
}

@media (min-width: 768px) {
  .image-container img {
    margin-bottom: 0; /* md:mb-0 */
    width: 100%; /* md:w-full */
    transform: rotate(-90deg); /* md:-rotate-90 */
    transition: all 0.3s ease-in-out; /* md:transition-all md:ease-in-out md:duration-300 */
  }
  .image-container img:hover {
    transform: rotate(0deg); /* md:hover:-rotate-0 */
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(-10%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from{
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Keyframes for the image sliding in from the left */
@keyframes slideInFromLeft {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.image-container {
  @apply flex items-center justify-center flex-shrink-0 mb-6 md:mb-0 md:mr-6 w-full md:w-2/5 md:-rotate-90 md:hover:-rotate-0 md:transition-all md:ease-in-out md:duration-300;
}

.image-container img {
  opacity: 0;
}

.about-bolded {
  font-weight: bold;
  color: #507eab;
}

.about-text {
  font-size: 1.125rem; /* text-lg */
  line-height: 1.75rem; /* Adjust line height for text-lg */
  color: #4a5568; /* text-gray-700 */
  margin-bottom: 1rem; /* mb-4 */
}

@media (min-width: 1024px) {
  .about-text {
    font-size: 1.25rem; /* lg:text-xl */
    line-height: 1.75rem; /* Adjust line height for text-xl */
  }
}

.about-content {
  width: 100%; /* w-full */
  padding-left: 0; /* Ensure no padding on smaller screens */
  margin-top: 0; /* Ensure no margin on smaller screens */
  animation: slideUp 1s ease-in-out forwards;
  opacity: 0;
}

@media (min-width: 768px) {
  .about-content {
    width: 60%; /* md:w-3/5 */
    padding-left: 1.25rem; /* md:pl-5 */
    margin-top: 5rem; /* md:mt-20 */
    animation: slideInFromRight 1s ease-in-out forwards;
  }
}

@media (min-width: 1024px) {
  .about-content {
    margin-top: 0; /* lg:mt-0 */
  }
}

.about-content h1 {
  font-size: 1.5rem; /* text-2xl */
  line-height: 2rem; /* Adjust line height for text-2xl */
  font-weight: 700; /* font-bold */
  margin-bottom: 1rem; /* mb-4 */
  background: linear-gradient(to right, #4facfe 0%, #87cefa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 768px) {
  .about-content h1 {
    font-size: 1.875rem; /* md:text-3xl */
    line-height: 2.25rem; /* Adjust line height for text-3xl */
  }
}

@media (min-width: 1024px) {
  .about-content h1 {
    font-size: 1.875rem; /* lg:text-3xl */
    line-height: 2.25rem; /* Adjust line height for text-3xl */
  }
}

.abme {
  width: 100%; /* w-full */
  min-height: 100vh; /* min-h-screen */
  background-color: white; /* bg-white */
  display: flex; /* flex */
  flex-direction: column; /* flex-col */
  align-items: center; /* items-center */
}

.abme-page {
  @apply pt-20 flex flex-col md:flex-row items-center justify-between w-full min-h-screen md:pl-44 md:pr-20 p-8 overflow-auto;
  background: linear-gradient(to top left, #eaeaea 0%, #d1e7f4 100%);
  width: max;
}

/* ----- END About Me ----- */


/* ----- Projects ----- */

.animated-typing-container {
  min-height: 120px; /* Adjust this value to ensure enough space for two lines */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  height: auto;
}

.projects-container {
  background: linear-gradient(to bottom right, #1a202c 0%, #1b212d 25%, #1c232e 50%, #1d252f 75%, #202736 100%);
  margin: 0;
  @apply pt-20 p-8 min-h-screen w-auto md:px-44 overflow-auto;
}

/* md:pl-44 md:pr-20 */

.projects-cards-container {
  @apply flex flex-col lg:flex-row justify-around;
}

.cards-col-1 {
  @apply flex-1 flex flex-col lg:pr-10 lg:items-end items-center transition-all duration-1000 ease-in-out transform;;
}

.cards-col-2 {
  @apply flex-1 flex lg:pl-10 flex-col lg:items-start items-center mt-0 lg:mt-24 transition-all duration-1000 ease-in-out transform;;
}

.project-card {
  @apply relative w-full bg-white mb-16 shadow-lg cursor-pointer hover:scale-105 transition-all ease-in-out duration-300;
}

.card-content {
  @apply absolute inset-0 flex flex-col justify-evenly md:justify-end p-4;
}

.card-image-container {
  @apply flex justify-center items-center h-auto;
}

.project-image {
  @apply w-full object-contain rounded-t-lg;
}

.card-text-container {
  @apply flex flex-col justify-center items-center pt-4 md:p-4;
}

@media (min-width: 1024px ) and (max-width: 1400px ) {
  
  .card-text-container {
    padding: 0px;
    padding-top: 16px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }

  .card-content {
    justify-content: space-evenly;
  }

}

.selected-project-screen {
  @apply fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-10 pt-12;
}

.selected-project-container {
  @apply bg-white p-10 rounded-lg shadow-lg max-w-5xl w-full flex flex-col md:flex-row overflow-y-auto;
}

.selected-project-image-container {
  @apply flex-shrink-0 flex items-center justify-center;
}

.selected-project-image {
  @apply rounded-lg max-w-full md:max-w-sm m-5;
}

.selected-project-info-container {
  @apply flex-1 flex flex-col mb-4 md:mb-0;
}

.tech-stack-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tech-stack-item {
  position: relative;
  margin: 10px;
}

.tech-stack-link {
  display: block;
  width: 40px;
  height: 40px;
}

.tech-stack-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  @apply hover:scale-125 transition-all ease-in-out duration-300;
}

.proj-tooltip {
  visibility: hidden;
  white-space: nowrap; /* Prevent text wrapping */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px; /* Adjust padding for better spacing */
  position: absolute;
  z-index: 1;
  bottom: 135%; /* Position the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tech-stack-link:hover .proj-tooltip {
  visibility: visible;
  opacity: 1;
}

.selected-project-buttons-container {
  @apply flex space-x-4 justify-evenly items-center md:justify-between mb-4;
}

.button-github {
  @apply mr-4 px-4 py-2 bg-gray-800 text-white rounded transition-transform duration-300 hover:rotate-12 hover:bg-gray-700;
}

.button-demo {
  @apply px-4 py-2 bg-blue-600 text-white rounded transition-transform duration-300 hover:rotate-12 hover:bg-blue-700;
}

.button-close {
  @apply px-4 py-2 bg-red-600 text-white rounded transition-transform duration-300 hover:rotate-12 hover:bg-red-700;
}

/* ----- END Projects ----- */


/* ----- Experiences ----- */



/* ----- END Experiences ----- */
.experience-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  background-color: #f8f9fa;
}

.experience-card {
  background-color: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.experience-company {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.experience-position {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.experience-duration,
.experience-location {
  font-size: 1rem;
  font-weight: 500;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.experience-description {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

.experience-description li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.experience-item {
  position: relative;
  padding: 20px;
  margin-bottom: 40px;
  width: 80%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.experience-content {
  text-align: left;
}

.experience-line {
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  width: 2px;
  height: 40px;
}

.experience-line .line {
  position: absolute;
  width: 2px;
  height: 100%;
  background: #4A4A4A;
}

.experience-line .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: #4A4A4A;
  border-radius: 50%;
}



/* ----- Footer ----- */

.footer {
  @apply bg-[#132230] text-white flex flex-col md:flex-row justify-around items-center gap-10 p-10;
}

.footer-inv {
  @apply text-[#4a5568] flex flex-col md:flex-row justify-around items-center gap-10 p-10;
  background: linear-gradient(to top left, #eaeaea 0%, #d5f0ff 100%);
}

.footer-container {
  @apply mx-auto px-4 flex flex-col md:flex-row justify-between items-center;
}

.footer-container p {
  @apply text-center md:text-left mb-4 md:mb-0;
}

/* ----- END Footer ----- */


/* ----- Micro Components ----- */

.waving-animation img {
  @apply w-64 md:w-72 lg:w-96 h-auto rounded-lg transition-all duration-300 ease-in-out hover:scale-105;
}


.carousel .control-arrow {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.carousel .control-arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}

.carousel .control-next.control-arrow:before,
.carousel .control-prev.control-arrow:before {
  color: white;
  font-size: 20px;
}

.photo-grid {
  margin: 100px 0 50px;
  overflow: hidden;
}

.photo-grid__content {
  display: flex;
  justify-content: space-between;
  margin: 0 -25px;
}

.photo-grid__content .col {
  width: 50%;
  padding: 0 25px;
}

.photo {
  max-width: 100%;
  position: relative;
  margin-bottom: 25px;
}

.photo-grid__footer {
  text-align: center;
  margin-top: 50px;
}

.btn {
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  background: var(--line);
  color: var(--bg);
  border-radius: 5px;
  transition: background 0.3s;
}

.btn__fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--line);
  border-radius: 5px;
  transform: translate(-15.6624px, -6.18584px);
}

.btn__label {
  position: relative;
  z-index: 1;
}

.btn:hover .btn__fill {
  background: var(--subtext);
}

/* Add this CSS to your main CSS file or inside a <style> tag in your component file */
@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.animate-in {
  animation: slideIn 1s forwards;
}

.animate-out {
  animation: slideOut 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

/* ----- END Micro Components ----- */